import React, {useCallback, useEffect, useState} from 'react'
import {
    Input,
    Label,
    HelperText
} from '@windmill/react-ui'

import {NavLink, withRouter} from 'react-router-dom'
import {useStoreState} from "easy-peasy";

import {BsPencil} from "react-icons/bs";

import debounce from "lodash/debounce";
import {useLoadProjectQuery} from "../../hooks/useLoadProjectQuery";

import {
    useMutation,
} from 'react-query'
import {changeProjectLabel, projectIcons} from "../../helpers/projectHelpers";
import SecondaryMenu from "./SecondaryMenu";
import HamburgerMenu from "./HamburgerMenu";
import Logo from "./Logo";
import Icons from "./Icons";
import {useUserQuery} from "../../hooks/useUserQuery";
import PageTitle from "../Typography/PageTitle";


function ProjectHeader() {
    const activeProjectId = useStoreState((state) => state.activeProjectId);
    const [isLabelSaved, setIsLabelSaved] = useState(false);
    const [isLabelValid, setIsLabelValid] = useState(true);
    const [icons, setIcons] = useState(projectIcons);

    const {
        isLoading: projectIsLoading,
        isFetching: projectIsFetching,
        error: projectError,
        data: projectData
    } = useLoadProjectQuery(activeProjectId);

    const changeProjectLabelMutation = useMutation((payload) => {
        return changeProjectLabel(payload);
    }, {
        onSuccess: (response) => {
            // met timeout
            setIsLabelSaved(true);
            setTimeout(() => {
                setIsLabelSaved(false);
            }, 5000)
        },
    })

    const handleProjectLabelChange = (label, id) => {
        setIsLabelSaved(false);
        if (label.length < 3 || label.length > 200) {
            setIsLabelValid(false)

        } else {
            setIsLabelValid(true)
            changeProjectLabelMutation.mutate({id, label})
        }
    }


    const debounceProjectLabelChange = useCallback(debounce(handleProjectLabelChange, 400), []);
    const {data: userData} = useUserQuery();
    useEffect(() => {
            let icons = {...projectIcons}

            Object.keys(icons).map((key) => {

                if (key == "project_info") {
                    //icons[key].label = projectData?.label;
                } else if (key == "specifications") {


                    icons[key].disabled = (!projectData?.articles || (projectData?.articles && !projectData.articles.length));
                } else if (key == "prices") {
                    icons[key].disabled = (!projectData?.has_measurements);


                    if (userData?.guestAccount === true) {
                        icons[key].label = "Offerte";

                        if (projectData.unanswered) {
                            icons[key].disabled = true;
                        }
                    }

                }
            });
            setIcons(icons);
        }
        , [projectData, projectIcons])

    return (
        <div className={"flex items-center justify-between w-full"}>
            <div className={"lg:w-1/3 w-full flex space-x-5 justify-start items-center"}>

                <NavLink to={"/app"} className={"relative z-40"}>
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <circle cx="25" cy="25" r="25" fill="#F2F7FD"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M25.126 13C18.8469 13 13.7567 18.0902 13.7567 24.3693V24.8571C13.7567 25.6628 13.8405 26.4489 13.9998 27.2071C13.9999 27.2074 13.9999 27.2076 14 27.2078C15.0277 32.2128 12.9855 33.9692 12.371 34.4724C11.7626 34.9801 11.7835 36.2265 13.3442 36.2265L24.4072 36.2265C24.4072 36.2265 24.4007 36.2185 24.388 36.2029C24.632 36.2185 24.8781 36.2265 25.126 36.2265H27.5066C33.7858 36.2265 38.876 31.1363 38.876 24.8572V24.3693C38.876 18.0902 33.7857 13 27.5066 13H25.126ZM26.1534 22.8599C27.4232 22.8599 28.4527 21.8305 28.4527 20.5606C28.4527 19.2908 27.4232 18.2614 26.1534 18.2614C24.8835 18.2614 23.8541 19.2908 23.8541 20.5606C23.8541 21.8305 24.8835 22.8599 26.1534 22.8599ZM28.4527 28.662C28.4527 29.9319 27.4232 30.9613 26.1534 30.9613C24.8835 30.9613 23.8541 29.9319 23.8541 28.662C23.8541 27.3922 24.8835 26.3627 26.1534 26.3627C27.4232 26.3627 28.4527 27.3922 28.4527 28.662Z"
                              fill="#003180"/>
                        <mask id="mask0_3144_10161" maskUnits="userSpaceOnUse" x="12" y="13"
                              width="27" height="24">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M25.126 13C18.8469 13 13.7567 18.0902 13.7567 24.3693V24.8571C13.7567 25.6628 13.8405 26.4489 13.9999 27.2072C13.9999 27.2074 13.9999 27.2076 14 27.2078C15.0277 32.2128 12.9855 33.9692 12.371 34.4724C11.7626 34.9801 11.7835 36.2265 13.3442 36.2265L24.4072 36.2265C24.4072 36.2265 24.4007 36.2185 24.388 36.2029C24.632 36.2185 24.8781 36.2265 25.126 36.2265H27.5066C33.7858 36.2265 38.876 31.1363 38.876 24.8572V24.3693C38.876 18.0902 33.7857 13 27.5066 13H25.126ZM26.1534 22.8599C27.4232 22.8599 28.4527 21.8305 28.4527 20.5606C28.4527 19.2908 27.4232 18.2614 26.1534 18.2614C24.8835 18.2614 23.8541 19.2908 23.8541 20.5606C23.8541 21.8305 24.8835 22.8599 26.1534 22.8599ZM28.4527 28.662C28.4527 29.9319 27.4232 30.9613 26.1534 30.9613C24.8835 30.9613 23.8541 29.9319 23.8541 28.662C23.8541 27.3922 24.8835 26.3627 26.1534 26.3627C27.4232 26.3627 28.4527 27.3922 28.4527 28.662Z"
                                  fill="#0066DE"/>
                        </mask>
                        <g mask="url(#mask0_3144_10161)">
                            <path
                                d="M13.708 13L38.8565 13V24.7529C38.8565 24.7529 36.5027 23.9864 32.5694 23.9864C28.6361 23.9864 23.1958 25.5193 19.41 25.5193C15.6242 25.5193 13.708 24.7529 13.708 24.7529L13.708 13Z"
                                fill="#004BBB"/>
                        </g>
                    </svg>
                </NavLink>

                <h1 className={"text-2xl font-bold w-96"}>
                    {projectData?.label}
                </h1>
            </div>

            <div className={"lg:block hidden w-1/3 flex items-center text-lg justify-center"}>
                <Icons icons={icons} id={activeProjectId}/>
            </div>

            <div className={"lg:block hidden w-1/3"}>

            </div>
        </div>
    )
}

export default withRouter(ProjectHeader)
