import React, {useContext} from 'react'

import {NavLink, useLocation} from "react-router-dom";
import {t} from "../../helpers/helpers";

function Icons({icons, id, className}) {
    const json = Object.keys(icons).filter((view) => icons[view].articleView === true)
    let location = useLocation();

    return (
        <div
            className={className + " flex justify-start items-center space-x-5"}>
            {json.map((view) => {
                const link = icons[view]?.link.replace('[ID]', id);
                let label = (icons[view].label ? icons[view].label : t(view, 'nl'));



                return (
                    <NavLink to={link} key={view}
                             className={(location.pathname.indexOf(link) != -1 ? "text-primary-1000 border-b-3 border-primary-main" : "text-medium_emphasis") + (icons[view]?.disabled ? " opacity-50" : "") + " flex justify-start space-x-2 py-3 items-center cursor-pointer"}
                    >
                        <div>{icons[view]?.icon}</div>

                        <div>{label.replace(/ /g, "\u00A0")}</div>
                    </NavLink>)
            })}
        </div>
    )
}

export default Icons
