import {kokoFetch} from "./fetchHelpers";
import {toast} from "react-toastify";


import React from "react";


export function saveUserDetails(payload, manualSave, history) {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/user/update/${payload.id}`, 'POST', {
        ...payload
    })
        .then((response) => {

            if (manualSave) {
                //history.goBack();
                window.location = window.location;
            }
            return response.data;
        })
        .catch((response) => {
            console.log("-> " + response);
            toast.error(`Er ging iets mis..`)
        })
}


export function updateUser(payload) {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/user/save`, 'post', {...payload, type: 'User'})
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis!`)
        })
}

export function loadUser() {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/profile`)
        .then((response) => {
            if(typeof response === 'undefined') {
                alert("Je bent niet meer ingelogd");
                window.location = "/login";
            }
            return response.data;
        })
        .catch((response) => {

            toast.error(`Er ging iets mis..`)
        })
}

export function loadAllUsers() {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/users/calqi-stats`, 'POST')
        .then((response) => {

            return response.data;
        })
        .catch((response) => {
            toast.error(`Er ging iets mis..`)
        })
}
