import {kokoFetch} from "./fetchHelpers";
import {toast} from "react-toastify";
import TypeAgreement from "../components/KokoComponents/Article/TypeAgreement";
import UnitAgreement from "../components/KokoComponents/Article/UnitAgreement";
import React from "react";
import {thunk} from "easy-peasy";
import axios from "axios";
import {preformatFloat} from "./calculationHelpers";

export function buildArticleTree(articles) {
    // eerst alles aflopen waar geen parent is en wel children
    return [];

    const levels = 5;
    let articleTree = [];

    for (let i = 5; i > 0; i--) {
        let articlesCurrentLevel = Object.keys(articles).filter((article) => {
            return articles[article].level === i;
        })
        articlesCurrentLevel.forEach((article) => {
            //console.log("diarree", article);
            const currentArticle = articles[article];
            const newArticle = {
                key: currentArticle.number,
                number: currentArticle.number,
                label: currentArticle.title,
                description: currentArticle.description
            }
            if (currentArticle.hasOwnProperty("nodes")) {
                newArticle['nodes'] = currentArticle.nodes;
            }

            // als parent er is aan parent toevoegen
            // anders gewoon onderaan

            if (currentArticle.parent) {
                // tijdelijk ook articles toegevoegd, wordt bij volgende iteratie toegevoegd
                articles[currentArticle.parent]['nodes'] = articles[currentArticle.parent].hasOwnProperty("nodes") && Array.isArray(articles[currentArticle.parent]['nodes']) ?
                    [...articles[currentArticle.parent]['nodes'], newArticle] : [newArticle];
            } else {
                // direct aan tree toevoeggen want heeft geen parent
                articleTree = [...articleTree, newArticle]
            }


            //articleTree[article]
        })
    }

    console.log("tree", articleTree);
    return articleTree;

}

export function loadArticlesCompressed() {
    return kokoFetch(process.env.REACT_APP_API_URL + '/api/article-compressed').then(res => {
            return {
                articles: res.data
            };
        }
    )
}

export function loadArticles() {
    return kokoFetch(process.env.REACT_APP_API_URL + '/api/article').then(res => {
            const processedArticles = processArticles(res.data);
            const articlesTree = buildArticleTree(processedArticles);
            return {
                articles: processedArticles,
                articlesTree: articlesTree.slice(0, 10)
            };
        }
    )
}

export function loadArticleTree() {

    return kokoFetch(process.env.REACT_APP_API_URL + '/api/article-tree').then(res => {
            return res.data;
        }
    )
}

export function processArticles(articles) {
    const updatedArticles = {...articles};


    Object.keys(updatedArticles).forEach((articleKey) => {

        if (!articleKey.endsWith(".")) {
            updatedArticles[articleKey + "."] = {...updatedArticles[articleKey], number: articleKey};
            delete updatedArticles[articleKey];
        }

    })
    Object.keys(updatedArticles).forEach((articleKey) => {

        // parent zetten bij child en bij parent children array
        // remove trailing
        let articleNumberArray = articleKey.split(".");

        if (articleNumberArray) {
            const level = articleNumberArray.length;
            updatedArticles[articleKey]['level'] = level - 1;
            //console.log("dit het child", articleNumberArray)
            articleNumberArray.pop();
            articleNumberArray.pop();
            //console.log("dit de parent", articleNumberArray)

            const possibleParent = articleNumberArray.join(".") + ".";
            if (updatedArticles.hasOwnProperty(possibleParent)) {
                updatedArticles[possibleParent]['children'] = Array.isArray(updatedArticles[possibleParent]['children']) ?
                    [...updatedArticles[possibleParent]['children'], articleKey] : [articleKey];
                updatedArticles[articleKey]['parent'] = possibleParent;
            }
        }


    })

    return updatedArticles;
}


export function saveArticleText(payload) {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/article-text/save`, 'POST', {
        ...payload
    })
        .then((response) => {
            toast.success("Tekst bewaard.")

            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}



export function updateExistingArticleTextSimple({id, text}) {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/article-text/update/${id}`, 'POST', {
        text
    })
        .then((response) => {
            //toast.success("Tekst bewaard.")

            return response.data;
        })
        .catch(() => {
            console.log(`Er ging iets mis.`)
            //toast.error(`Er ging iets mis`)
        })
}

export function updateExistingArticleText(payload) {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/article-text/update-existing`, 'POST', {
        ...payload
    })
        .then((response) => {
            //toast.success("Tekst bewaard.")

            return response.data;
        })
        .catch(() => {
            console.log(`Er ging iets mis // jos vangt dit op`)
            //toast.error(`Er ging iets mis`)
        })
}

export function loadArticleTexts(payload) {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/article-text/load`, 'POST', {
        ...payload
    })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export function loadSectionTexts(section) {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/section-texts/${section}`, 'POST')
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export function measurementsSave(payload) {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/article-measurements/save`, 'POST', {
        ...payload
    })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

// todo: dit in backend laten gebeuren ?
export function groupArticles(articles) {

    const groupedArticles = {};
    Object.values(articles).forEach(article => {
        groupedArticles[article.section] =
            Array.isArray(groupedArticles[article.section]) ?
                [...groupedArticles[article.section], article] : [article];
    })

    return groupedArticles;
}

export function groupArticlesAdvanced(articles) {

    //doet hetzelfde als bovenstaande functie maar houdt ook ineens heel het sectie object bij ipv enkel de sectienaam
    const groupedArticles = {};
    Object.values(articles).forEach(article => {

        if (!groupedArticles[article.section]) {
            groupedArticles[article.section] = {"object": null, "children": []};
        }
        
        if (article.section === article.number) {
            groupedArticles[article.section]["object"] = article;
        }

        groupedArticles[article.section]["children"] = [...groupedArticles[article.section]["children"], article];
    })

    return groupedArticles;
}

export function checkIfArticleIsParent(articleNumber) {
    return (articleNumber.match(/\./g) || []).length === 1;
}


export function calculateArticleTotalsServer(projectArticle) {
    return kokoFetch(
        `${process.env.REACT_APP_API_URL}/api/project-article/load-calculation/${projectArticle}`,
        'POST'
    )
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export function getMeasurementSum(total, line, type) {
    return total + parseInt(line[type]);
}

export async function getData(){
    const res = await axios('/data');
    return await res.json();
}

export function calculateArticleTotals(lines) {
    const totals = {
        number: 0,
        length: 0,
        height: 0,
        width: 0
    };

    if (lines) {
        lines.forEach((line) => {
            const length = parseInt(line.length) ? parseInt(line.length) : 0;
            const height = parseInt(line.height) ? parseInt(line.height) : 0;
            const number = parseInt(line.number) ? parseInt(line.number) : 0;
            const width = parseInt(line.width) ? parseInt(line.width) : 0;

            totals.length += length;
            totals.height += height;
            totals.number += number;
            totals.width += width;
        });
    }

    return totals;
}

export const updateLabel = (articleId, label) => {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/library/article/label`, 'POST',
        {articleId, label}
    )
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export const updateLibraryArticle = (articleId, type, unit, targetPrice, defaultAccountText) => {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/library/article/update`, 'POST',
        {articleId, type, unit, targetPrice, defaultAccountText}
    )
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export const selectParent = (articleId, allArticles, articleList = []) => {


    const article = allArticles.find((a) => a.id === articleId);

    if (!article) return articleList;

    if (article.parentId) {
        articleList.push(article.parentId);

        //const parentArticle = allArticles.find((a) => a.id === article.parentId);
        selectParent(article.parentId, allArticles, articleList);
    }
    return articleList;
};

export const loadProjectArticle = (projectArticleId) => {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/article/${projectArticleId}`)
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}



export const loadArticle =  (article) => {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/article-detail/${article}`)
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export const articleViews = [
    'description',
    'materials',
    'execution',
    'measurement',
    'examination'
];

export const typesAgreement = {
    none: {
        color: '#ffe700',
        opacity: 0.3,
        type: 'none'
    },
    FH: {
        color: '#BBFC01',
        opacity: 0.3,
        type: 'with_unit'
    },
    VH: {
        color: '#01FCCF',
        opacity: 0.3,
        type: 'with_unit'
    },
    TP: {
        color: '#8DFC01',
        opacity: 0.3,
        type: 'no_unit'
    },
    SOG: {
        color: '#FCC501',
        opacity: 0.3,
        type: 'no_unit'
    },
    PM: {
        color: '#FC01A7',
        opacity: 0.3,
        type: 'no_unit'
    },

    TVS: {
        color: '#01FC47',
        opacity: 0.3,
        type: 'with_unit'
    }
};

export const measuringUnits = {
    none: {
        color: '#ffe700',
        opacity: 0.3,
        type: 'none'
    },
    m: {
        color: '#ffe700',
        opacity: 0.3,
        type: 'measurements'
    },
    m2: {
        color: '#74ee15',
        opacity: 0.3,
        type: 'measurements'
    },

    m3: {
        color: '#4deeea',
        opacity: 0.3,
        type: 'measurements'
    },

    st: {
        color: '#f000ff',
        opacity: 0.3,
        type: 'single_quantity'
    },
    kg: {
        color: '#01FC47',
        opacity: 0.3,
        type: 'multiple_quantity'
    },
    ton: {
        color: '#F400FF',
        opacity: 0.3,
        type: 'multiple_quantity'
    },
    month: {
        color: '#01FC47',
        opacity: 0.3,
        type: 'multiple_quantity'
    },
    week: {
        color: '#01FC47',
        opacity: 0.3,
        type: 'multiple_quantity'
    },
    day: {
        color: '#01FC47',
        opacity: 0.3,
        type: 'multiple_quantity'
    },
    hour: {
        color: '#01FC47',
        opacity: 0.3,
        type: 'multiple_quantity'
    },
    kW: {
        color: '#6F00EF',
        opacity: 0.3,
        type: 'multiple_quantity'
    },
    trap: {
        color: '#007FFF',
        opacity: 0.3,
        type: 'multiple_quantity'
    },
    trede: {
        color: '#77FB00',
        opacity: 0.3,
        type: 'multiple_quantity'
    }
};

export const fqUnits = {
    number: {
        color: '#4deeea',
        opacity: 0.3
    },
    length: {
        color: '#74ee15',
        opacity: 0.3
    }
};

export const getMeasurementDimension = (measurementUnit) => {
    let dimension = 0;
    switch (measurementUnit) {
        case 'm':
            dimension = 1;
            break;
        case 'm2':
            dimension = 2;
            break;
        case 'm3':
            dimension = 3;
            break;
        default:
            dimension = 0;
    }
    return dimension;
}


export const calculateLine = (number, dimension = 0, length = 0, width = 0, height = 0) => {
    let total;
    switch (dimension) {
        case 1:
            if (!isNaN(parseFloat(preformatFloat(length)))) {
                total = parseFloat(preformatFloat(length));
                break;
            }
        case 2:
            if (!isNaN(parseFloat(preformatFloat(length))) && !isNaN(parseFloat(preformatFloat(width)))) {
                total = parseFloat(preformatFloat(length)) * parseFloat(preformatFloat(width));
                break;
            }
        case 3:
            if (!isNaN(parseFloat(preformatFloat(length))) && !isNaN(parseFloat(preformatFloat(width))) && !isNaN(parseFloat(preformatFloat(height)))) {
                total = parseFloat(preformatFloat(length)) * parseFloat(preformatFloat(width)) * parseFloat(preformatFloat(height));
                break;
            }
        default:
            total = 0;
    }
    return total * number;
}

export const calculateTotals = (lines, measuringUnit) => {
    let total = 0;
    lines && lines.forEach(line => {
        total += calculateLine(line.number, getMeasurementDimension(measuringUnit), line.length, line.width, line.height);
    })
    return total;
}

export const formatOptionLabelType = ({value, label}) => (
    <div className={"flex flex-row items-center"}>
        <TypeAgreement typeAgreement={value}/>
        <div style={{marginLeft: "10px"}}>
            {label}
        </div>
    </div>
);

export const formatOptionLabelUnit = ({value, label}) => (
    <div className={"flex flex-row items-center"}>
        <UnitAgreement measuringUnit={value}/>
        <div style={{marginLeft: "10px"}}>
            {label}
        </div>
    </div>
);

export const measurementsTableHeader = {
    id: { title: "Art. Nummer", span: 1, key: "id" },
    article: { title: "Art. omschrijving", span: 1, key: "article" },
    type: { title: "Aard", span: 1, key: "type" },
    unit: { title: "Eenheid", span: 1, key: "unit" },
    amount: { title: "Hoeveelheid", span: 1, key: "amount" },
    price: { title: "Ramingsprijs", span: 1, key: "price" },
    total: { title: "Totaal", span: 1, key: "total" }
};

export const parseVmswNumber = (number) => {

    const partsArray = {
        part : "",
        chapter : "",
        paragraph :"",
        article: "",
        subArticles: {}
    };

    const parts = number.split(".");
    if(parts.length >= 1) {
        if(parts[0].length >= 1){
            partsArray.part = parts[0][0];
        }
        if(parts[0].length === 2){
            partsArray.chapter = parts[0][1];
        }
    }
    if(parts.length >= 2) {
        if(parts[1].length >= 1){
            partsArray.paragraph = parts[1][0];
        }
        if(parts[1].length === 2){
            partsArray.article = parts[1][1];
        }
    }
    if(parts.length >= 3) {
        for(let i = 2; i < parts.length; i++){
            let subGroup = {};
            if(parts[i].length >= 1){
                subGroup[0] = parts[i][0];
            }
            if(parts[i].length === 2){
                subGroup[1] = parts[i][1];
            }
            partsArray.subArticles[i] = subGroup;
        }
    }

    return partsArray;
}

export const buildVmswNumber = (partsArray) => {
    let number = "";

    if(partsArray.part !== ""){
        number += partsArray.part;
        if(partsArray.chapter !== ""){
            number += partsArray.chapter;
        }
        number += ".";
    }

    if(partsArray.paragraph !== ""){
        number += partsArray.paragraph;
        if(partsArray.article !== ""){
            number += partsArray.article;
        }
        if(partsArray.paragraph !== ""){
            number += ".";
        }
    }

    if(Object.keys(partsArray.subArticles).length > 0){
        Object.keys(partsArray.subArticles).forEach((key) => {
            number += partsArray.subArticles[key][0];
            if(partsArray.subArticles[key][1] !== ""){
                number += partsArray.subArticles[key][1];
            }
            if(partsArray.subArticles[key][0]){
                number += ".";
            }
        });
    }

    return number;
}



export function saveNewArticle(article) {
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/article-new`, 'POST', {
        ...article
    })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}
