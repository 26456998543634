import React from 'react'
import {Card, CardBody} from "@windmill/react-ui";
import {SpinnerDotted, SpinnerInfinity} from "spinners-react";
import {default as LoaderSpinner} from "react-loader-spinner";

const Loader = ({ simple = false}) =>
{


    if( simple ){
        return (
            <SpinnerInfinity size={50} thickness={100} speed={100} color="#fff"
                             secondaryColor="rgba(0, 102, 222, var(--bg-opacity))"/>
        )
    }

    return (
        <Card className="my-4 shadow-md">
            <CardBody>
                <SpinnerInfinity size={50} thickness={100} speed={100} color="#fff"
                                 secondaryColor="rgba(0, 102, 222, var(--bg-opacity))"/>
            </CardBody>
        </Card>

    )
}

export default Loader;