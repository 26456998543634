//import './wdyr';

import React, { lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useStoreActions } from "easy-peasy";
import { ToastContainer, toast } from "react-toastify";
import "./assets/scss/App.scss";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';


import "react-toastify/dist/ReactToastify.css";
import { kokoFetch } from "./helpers/fetchHelpers";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { createTheme, ThemeProvider } from "@material-ui/core";
import ProjectDetails from "./pages/ProjectDetails";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
//import Smartlook from "smartlook-client";

Sentry.init({
  dsn: "https://35563fdf61084f54b4c361cdce9b0386@o4504355116220416.ingest.sentry.io/4504588283150336",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#0066DE",
    },
    secondary: {
      main: "#0066DE",
    },
  },
});

const Layout = lazy(() => import("./containers/Layout"));
const AppSwitcher = lazy(() => import("./containers/AppSwitcher"));
const Login = lazy(() => import("./pages/Login"));
const Invite = lazy(() => import("./pages/Invite"));
const SignupInvite = lazy(() => import("./pages/SignupInvite"));
const Signup = lazy(() => import("./pages/SignUp"));
const Account = lazy(() => import("./pages/Account"));
const Upload = lazy(() => import("./pages/Upload"));
const OTP = lazy(() => import("./pages/SignupValidation"));
const LoginLinkCheck = lazy(() => import("./pages/LoginLinkCheck"));
const ChangeFirstTimePassword = lazy(() =>
  import("./pages/ChangeFirstTimePassword")
);

const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const queryClient = new QueryClient();

function App() {
  /*useEffect(() => {
    Smartlook.init("56debe92436b584473ebb4cf00895c3f864a3558");
  }, []);*/
  return (
    <>
      <script src="https://cdn.tailwindcss.com" />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <div className={"bg-primary-high_emphasis"}>
            <Router>
              <AccessibleNavigationAnnouncer />
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/signup" component={Signup} />
                <Route path="/upload-logo" component={Upload} />
                <Route path="/signup-otp" component={OTP} />
                <Route path="/create-new-account" component={Account} />
                <Route path="/create-account" component={CreateAccount} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/login-link-check" component={LoginLinkCheck} />
                <Route path="/invite" component={Invite} />
                <Route path="/signup-invite" component={SignupInvite} />
                <Route
                  path="/change-first-time-password"
                  component={ChangeFirstTimePassword}
                />

                {/*TODO: Sander - move to routes/index.js. This is just here for demo purpose.*/}
                {/*
                                <Route path="/app/project-details/:project?" component={ProjectDetails}/>
                                */}

                {/* Place new routes over this */}
                <Route path="/app" component={Layout} />
                {/* If you have an index page, you can remothis Redirect */}
                <Redirect exact from="/" to="/login" />
              </Switch>
            </Router>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            limit={3}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={"light"}
          />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
