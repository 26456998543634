import {createStore, action, thunk} from 'easy-peasy';
import axios from 'axios';
import {toast} from 'react-toastify';
import {kokoFetch} from "../helpers/fetchHelpers";
import {emptyLastActiveView} from "../helpers/projectHelpers";

export const store = createStore({
    projects: [],
    popup: false,
    selectedArticles: [],
    importLink: null,
    loading: false,
    uploading: false,
    searching: false,
    contact: [],
    zIndexCounter: 0,
    contractors: [],
    scrollTo: null,
    appStatus: {status: "idle"},
    activeProject: null,
    activeProjectId: 0,
    files: {},
    activeView: "specifications",
    articleFilters: [],
    headerIcons: null,
    articlesBySearch: [],
    controllers: [],
    lastActiveView: emptyLastActiveView,
    setLastActiveView: action((state, payload) => {
        state.lastActiveView = payload;
    }),
    setControllers: action((state, payload) => {
        state.controllers = payload;
    }),
    setHeaderIcons: action((state, payload) => {
        state.headerIcons = payload;
    }),
    setZIndexCounter: action((state, payload) => {
        state.zIndexCounter = payload;
    }),
    setScrollTo: action((state, payload) => {
        state.scrollTo = payload;
    }),
    setSelectedArticles: action((state, payload) => {
        state.selectedArticles = payload;
    }),
    setArticleFilter: action((state, payload) => {


        var f = false;
        for (var i = 0; i < state.articleFilters.length; i++) {
            if (state.articleFilters[i] == payload) {
                f = true;
                state.articleFilters.splice(i, 1);
                break;
            }
        }

        if (!f) {
            state.articleFilters.push(payload);
        }


    }),
    setActiveView: action((state, payload) => {
        state.activeView = payload;
    }),
    setAppStatus: action((state, payload) => {
        state.appStatus = payload;
    }),
    setActiveProject: action((state, payload) => {
        state.activeProject = payload;
    }),
    setActiveContact: action((state, payload) => {
        state.activeContact = payload;
    }),
    setActiveProjectId: action((state, payload) => {
        state.activeProjectId = payload;
    }),
    setActiveOrganisationId: action((state, payload) => {
        state.activeOrganisationId = payload;
    }),
    setActiveContactId: action((state, payload) => {
        state.activeContactId = payload;
    }),
    setActiveArticle: action((state, payload) => {
        state.activeArticle = payload;
    }),
    setSearching: action((state, payload) => {
        state.searching = payload;
    }),
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    updateProjectBestPricingOrganisation: thunk(async (actions, payload) => {


        return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/update-best-pricing-organisation/${payload.projectId}`, 'POST', {
            ...payload
        })
            .then((response) => {
                actions.setProjectContractor(payload);


                return response.data;
            })
            .catch(() => {
                toast.error(`Er ging iets mis`)
            })
    }),

    saveLinkedOfferRequest: thunk(async (actions, payload) => {


        return kokoFetch(`${process.env.REACT_APP_API_URL}/api/offer/request/link`, 'POST', {
            ...payload
        })
            .then((response) => {
                //TODO: bestaand object aanpassen;
                window.location = window.location;
                return response.data;
            })
            .catch(() => {
                toast.error(`Er ging iets mis`)
            })
    }),
    saveProjectDetails: thunk(async (actions, payload) => {

        console.log(payload);
        // console.log("payload", payload)
        return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/update/${payload.id}`, 'POST', {
            ...payload
        })
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                toast.error(`Er ging iets mis`)
            })
    }),
    addProject: action((state, payload) => {

        state.projects.push(payload);
        window.location = `/app/project/${payload.id}/project_info`
        //state.projects[payload] = {id: payload};
    }),
    addImportLink: action((state, payload) => {
        /* state.importLink = '/payload.projectId;*/
        window.location = `/app/project/${payload.projectId}/specifications`
    }),
    addContactSetting: action((state, payload) => {


        if (!state.contactSettings) {
            state.contactSettings = [];
        }


        var f = false;
        for (var i = 0; i < state.contactSettings.length; i++) {
            if (state.contactSettings[i].id == payload.id) {
                state.contactSettings[i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {

            if (payload.category) {
                if (!state.newSettings) {
                    state.newSettings = {};
                }

                state.newSettings[payload.category] = payload;
            }

            state.contactSettings.push(payload);
        }


    }),
    addOrganisationSetting: action((state, payload) => {


        if (!state.organisationSettings) {
            state.organisationSettings = [];
        }


        var f = false;
        for (var i = 0; i < state.organisationSettings.length; i++) {
            if (state.organisationSettings[i].id == payload.id) {
                state.organisationSettings[i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {

            if (payload.category) {
                if (!state.newSettings) {
                    state.newSettings = {};
                }

                state.newSettings[payload.category] = payload;
            }

            state.organisationSettings.push(payload);
        }


    }),
    addSetting: action((state, payload) => {


        if (!state.settings) {
            state.settings = [];
        }


        var f = false;
        for (var i = 0; i < state.settings.length; i++) {
            if (state.settings[i].id == payload.id) {
                state.settings[i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {

            if (payload.category) {
                if (!state.newSettings) {
                    state.newSettings = {};
                }

                state.newSettings[payload.category] = payload;
            }

            state.settings.push(payload);
        }


    }),
    addAddress: action((state, payload) => {


        if (!state.addresses) {
            state.addresses = [];
        }


        var f = false;
        for (var i = 0; i < state.addresses.length; i++) {
            if (state.addresses[i].id == payload.id) {
                state.addresses[i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {
            state.addresses.push(payload);
        }
    }),
    addOrganisationAddress: action((state, payload) => {


        if (!state.organisationAddresses) {
            state.organisationAddresses = [];
        }


        var f = false;
        for (var i = 0; i < state.organisationAddresses.length; i++) {
            if (state.organisationAddresses[i].id == payload.id) {
                state.organisationAddresses[i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {
            state.organisationAddresses.push(payload);
        }
    }),
    addMessage: action((state, payload) => {


        if (!state.messages) {
            state.messages = [];
        }


        var f = false;
        for (var i = 0; i < state.messages.length; i++) {
            if (state.messages[i].id == payload.id) {
                state.messages[i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {
            state.messages.push(payload);
        }
    }),
    removeEntity: action((state, payload) => {

        try {

            const message = payload.entity + ' werd verwijderd.';

            axios.post(process.env.REACT_APP_API_URL + '/api/' + payload.entity + '/delete/' + payload.id, payload);

            if (payload.entity == "message") {
                if (state.messages) {
                    for (var i = 0; i < state.messages.length; i++) {
                        if (state.messages[i].id == payload.id) {
                            state.messages.splice(i, 1);
                            break;
                        }
                    }
                }
            } else if (payload.entity == "contact") {
                if (state.contacts) {
                    for (var i = 0; i < state.contacts.length; i++) {
                        if (state.contacts[i].id == payload.id) {
                            state.contacts.splice(i, 1);
                            break;
                        }
                    }
                }
            } else if (payload.entity == "news") {
                if (state.news) {
                    for (var i = 0; i < state.news.length; i++) {
                        if (state.news[i].id == payload.id) {
                            state.news.splice(i, 1);
                            break;
                        }
                    }
                }
            } else if (payload.entity == "project") {
                if (state.projects) {
                    for (var i = 0; i < state.projects.length; i++) {
                        if (state.projects[i].id == payload.id) {
                            state.projects.splice(i, 1);
                            break;
                        }
                    }
                }
            } else if (payload.entity == "organisation") {
                if (state.organisations) {
                    for (var i = 0; i < state.organisations.length; i++) {
                        if (state.organisations[i].id == payload.id) {
                            state.organisations.splice(i, 1);
                            break;
                        }
                    }
                }
            }

            //TODO: loadProfile nog eens aanroepen
            if (payload.entity == "message") {

            }


            /*if (state.settings) {
                for (var i = 0; i < state.settings.length; i++) {
                    if (state.settings[i].id == payload.id) {
                        state.settings.splice(i, 1);
                        break;
                    }
                }
            }*/


        } catch (err) {

        }


    }),
    removeSetting: action((state, payload) => {

        try {

            const message = 'Contact ' + payload.firstName + ' werd verwijderd.';

            axios.post(process.env.REACT_APP_API_URL + '/api/settings/delete', payload);

            if (payload.type == "contact") {
                if (state.contactSettings) {
                    for (var i = 0; i < state.contactSettings.length; i++) {
                        if (state.contactSettings[i].id == payload.id) {
                            state.contactSettings.splice(i, 1);
                            break;
                        }
                    }
                }
            } else if (payload.type == "organisation") {
                if (state.organisationSettings) {
                    for (var i = 0; i < state.organisationSettings.length; i++) {
                        if (state.organisationSettings[i].id == payload.id) {
                            state.organisationSettings.splice(i, 1);
                            break;
                        }
                    }
                }
            } else {
                if (state.settings) {
                    for (var i = 0; i < state.settings.length; i++) {
                        if (state.settings[i].id == payload.id) {
                            state.settings.splice(i, 1);
                            break;
                        }
                    }
                }
            }


        } catch (err) {

        }


    }),
    removeAddress: action((state, payload) => {

        if (state.organisationAddresses && payload.parent == "organisation") {
            for (var i = 0; i < state.organisationAddresses.length; i++) {
                if (state.organisationAddresses[i].id == payload.id) {
                    state.organisationAddresses.splice(i, 1);
                    break;
                }
            }
        } else if (state.addresses) {
            for (var i = 0; i < state.addresses.length; i++) {
                if (state.addresses[i].id == payload.id) {
                    state.addresses.splice(i, 1);
                    break;
                }
            }
        }
    }),
    removeOrganisationContact: action((state, payload) => {


        if (state.organisationContacts) {
            for (var i = 0; i < state.organisationContacts.length; i++) {
                if (state.organisationContacts[i].id == payload.id) {
                    state.organisationContacts.splice(i, 1);
                    break;
                }
            }
        }
    }),
    removeFile: action((state, payload) => {
        console.log(payload)
        axios.post(process.env.REACT_APP_API_URL + '/api/remove-project-file', {
            "projectId": payload.id,
            "fileId": payload.file.id
        });
        if (state.files[payload.type]) {
            for (var i = 0; i < state.files[payload.type].length; i++) {
                if (state.files[payload.type][i].id == payload.file.id) {
                    state.files[payload.type].splice(i, 1);
                    break;
                }
            }
        }
    }),
    removeProjectTeam: action((state, payload) => {


        if (state.projectTeam) {
            for (var i = 0; i < state.projectTeam.length; i++) {
                if (state.projectTeam[i].id == payload.id) {
                    state.projectTeam.splice(i, 1);
                    break;
                }
            }
        }
    }),
    removeProjectContact: action((state, payload) => {


        if (state.projectContacts) {
            for (var i = 0; i < state.projectContacts.length; i++) {
                if (state.projectContacts[i].id == payload.id) {
                    state.projectContacts.splice(i, 1);
                    break;
                }
            }
        }
    }),
    removeProjectOrganisation: action((state, payload) => {


        if (state.projectOrganisations) {
            for (var i = 0; i < state.projectOrganisations.length; i++) {
                if (state.projectOrganisations[i].id == payload.id) {

                    state.projectOrganisations.splice(i, 1);
                    break;
                }
            }
        }
    }),
    removeContactOrganisation: action((state, payload) => {


        if (state.contactOrganisations) {
            for (var i = 0; i < state.contactOrganisations.length; i++) {
                if (state.contactOrganisations[i].id == payload.id) {
                    state.contactOrganisations.splice(i, 1);
                    break;
                }
            }
        }
    }),
    addContactOrganisations: action((state, payload) => {
        if (payload) {
            state.contactOrganisations = payload;
        }
    }),
    addOrganisationAddresses: action((state, payload) => {

        if (payload) {

            console.log(payload);
            state.organisationAddresses = payload;
        }

    }),
    addAddresses: action((state, payload) => {

        if (payload) {

            state.addresses = payload;
        }

    }),

    updateFileCrop: action((state, payload) => {
        for (var i = 0; i < state.files[payload.type].length; i++) {

            if (state.files[payload.type][i].id == payload.id) {
                payload.crop.random = Math.floor(Math.random() * 10000);
                state.files[payload.type][i].crop = payload.crop;

                console.log(payload);
                break;
            }
        }
    }),
    setFiles: action((state, payload) => {
        state.files = payload
    }),
    setUploading: action((state, payload) => {
        state.uploading = payload;
    }),
    addFile: action((state, payload) => {
        if (!state.files) {
            state.files = {};
        }

        if (!state.files[payload.type]) {
            state.files[payload.type] = [];
        }


        var f = false;
        for (var i = 0; i < state.files[payload.type].length; i++) {
            if (state.files[payload.type][i].id == payload.id) {
                state.files[payload.type][i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {
            state.files[payload.type].push(payload);
        }
    }),

    addFiles: action((state, payload) => {

        if (payload) {
            /* state.files[payload.type] = payload.files;*/
            state.files = payload.files;

        }

    }),
    addProjectOrganisations: action((state, payload) => {

        if (payload) {

            state.projectOrganisations = payload;
        }

    }),
    addProjectContacts: action((state, payload) => {

        if (payload) {

            state.projectContacts = payload;
        }

    }),
    addContactOrganisation: action((state, payload) => {


        if (!state.contactOrganisations) {
            state.contactOrganisations = [];
        }


        var f = false;
        for (var i = 0; i < state.contactOrganisations.length; i++) {
            if (state.contactOrganisations[i].id == payload.id) {
                state.contactOrganisations[i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {
            state.contactOrganisations.push(payload);
        }


    }),
    addProjectOrganisation: action((state, payload) => {


        if (!state.projectOrganisations) {
            state.projectOrganisations = [];
        }


        var f = false;
        for (var i = 0; i < state.projectOrganisations.length; i++) {
            if (state.projectOrganisations[i].id == payload.id) {
                state.projectOrganisations[i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {

            state.projectOrganisations.push(payload);
        }


    }),
    addProjectContact: action((state, payload) => {


        if (!state.projectContacts/* || !state.projectContacts.length */) {
            state.projectContacts = [];
        }


        var f = false;
        for (var i = 0; i < state.projectContacts.length; i++) {

            if (state.projectContacts[i].id == payload.id) {
                state.projectContacts[i] = payload;
                f = true;
                break;
            }
        }


        state.contacts.push(payload);


        if (!f) {
            state.projectContacts.push(payload);
        }


    }),
    addProjectTeam: action((state, payload) => {


        if (!state.projectTeam/* || !state.projectContacts.length */) {
            state.projectTeam = [];
        }


        var f = false;
        for (var i = 0; i < state.projectTeam.length; i++) {

            if (state.projectTeam[i].id == payload.id) {
                state.projectTeam[i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {
            state.projectTeam.push(payload);

        }

    }),
    addOrganisationContacts: action((state, payload) => {
        if (payload) {
            state.organisationContacts = payload;
        }
    }),
    addOrganisationContact: action((state, payload) => {


        if (!state.organisationContacts) {
            state.organisationContacts = [];
        }


        var f = false;
        for (var i = 0; i < state.organisationContacts.length; i++) {
            if (state.organisationContacts[i].id == payload.id) {
                state.organisationContacts[i] = payload;
                f = true;
                break;
            }
        }

        if (!f) {
            state.organisationContacts.push(payload);
        }


    }),

    addOrganisation: action((state, payload) => {
        state.organisations.push(payload);
        //window.location = `/app/organisation/${payload.id}`
    }),
    addArticleCompressedById: action((state, payload) => {
        state.articlesCompressed[payload.id] = payload;
    }),
    addNews: action((state, payload) => {
        state.news.push(payload);
        window.location = `/app/news-detail/${payload.id}`

    }),
    addContact: action((state, payload) => {

        state.contacts.push(payload);
        window.location = `/app/contact/${payload.id}`

    }),
    setProjectTemplates: action((state, payload) => {
        state.projectTemplates = payload;
    }),
    setProjects: action((state, payload) => {
        state.projects = payload;
    }),
    setProjectContractors: action((state, payload) => {
        state.projectContractors = payload;
    }),
    setMessages: action((state, payload) => {
        state.messages = payload;
    }),
    setNews: action((state, payload) => {
        state.news = payload;
    }),
    setNewsItem: action((state, payload) => {
        state.newsItem = payload;
    }),
    setMessage: action((state, payload) => {
        state.message = payload;
    }),
    setContractorType: action((state, payload) => {
        state.contractorType = payload;
    }),
    setOrganisations: action((state, payload) => {
        state.organisations = payload;
    }),
    setArticlesBySearch: action((state, payload) => {
        state.articlesBySearch = payload;
    }),
    setArticlesCompressed: action((state, payload) => {
        state.articlesCompressed = payload;
    }),
    setArticlesParents: action((state, payload) => {
        state.articlesParents = payload;
    }),
    addArticleCompressed: action((state, payload) => {
        state.articlesCompressed[payload.number] = payload.id;
    }),
    setContacts: action((state, payload) => {
        state.contacts = payload;
    }),
    setAddresses: action((state, payload) => {
        state.addresses = payload;
    }),
    setProjectTeam: action((state, payload) => {
        state.projectTeam = payload;
    }),
    setProjectContacts: action((state, payload) => {
        state.projectContacts = payload;
    }),
    setProjectOrganisations: action((state, payload) => {
        state.projectOrganisations = payload;
    }),
    setArticleFacets: action((state, payload) => {
        state.articleFacets = payload;
    }),
    setUser: action((state, payload) => {
        state.user = payload;
    }),
    setContact: action((state, payload) => {
        state.contact = payload;
    }),
    setOrganisation: action((state, payload) => {
        state.organisation = payload;
    }),
    setProjectContractor: action((state, payload) => {
        for (var i = 0; i < state.projectContractors.length; i++) {
            if (state.projectContractors[i].organisation.id == payload.organisationId) {
                state.projectContractors[i].checked = payload.checked;
            } else {
                state.projectContractors[i].checked = false;
            }
        }
    }),
    setOrganisationSettings: action((state, payload) => {
        state.organisationSettings = payload;
    }),
    setContactSettings: action((state, payload) => {
        state.contactSettings = payload;
    }),
    setZipCodes: action((state, payload) => {
        state.zipCodes = payload;
    }),
    setSettings: action((state, payload) => {
        state.settings = payload;
    }),
    setContactTypes: action((state, payload) => {
        state.contactTypes = payload;
    }),

    setContactSubTypes: action((state, payload) => {
        state.contactSubTypes = payload;
    }),
    setError: action((state, payload) => {
        state.error = payload;
    }),
    createProjectFromTemplate: thunk(async (actions, payload) => {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/template-to-project/' + payload);
            toast.success(`Project ${data.label} werd aangemaakt.`)

            actions.addProject(data);
            window.location = `/app/project/${data.id}`


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken project.")

        }
    }),
    saveProject: thunk(async (actions, payload) => {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/project/new', payload);
            toast.success(`Project ${payload.label} werd aangemaakt.`)

            actions.addProject(data);


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken project.")
        }
    }),
    saveTemplate: thunk(async (actions, payload) => {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/template/new', payload);
            toast.success(`Template ${payload.label} werd aangemaakt. Bij het aanmaken van een nieuw project kan je hier van starten.`)


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken template.")

        }
    }),
    saveParentContact: thunk(async (actions, payload) => {
        try {

            const message = 'Contact ' + payload.firstName + ' werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/contact/new', payload);


            if (data.id) {
                toast.success(message)

                if (payload.widget == "project") {
                    actions.addProjectContact(data);
                } else {
                    actions.addOrganisationContact(data);
                }

            } else {

                toast.error("Probleem bij aanmaken contact.");
            }


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken contact.")

        }
    }),
    updateCrop: thunk(async (actions, payload) => {
        try {


            actions.updateFileCrop(payload);


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanpassen crop.")

        }
    }),

    saveParentFile: thunk(async (actions, payload) => {

        //try {

        actions.setUploading(true);
        const message = 'Bestand werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
        const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/file/update', payload);


        if (data.id) {
            //toast.success(message)

            actions.setUploading(false);
            actions.addFile(data);


        } else {

            toast.error("Probleem bij aanmaken file.");
        }


        /*} catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken file.")

        }*/
    }),
    saveFiles: thunk(async (actions, payload) => {
        actions.setFiles(payload);
    }),
    saveProjectOrganisations: thunk(async (actions, payload) => {
        actions.setProjectOrganisations(payload);
    }),
    saveProjectTeam: thunk(async (actions, payload) => {
        actions.setProjectTeam(payload);
    }),
    setPopup: action((state, payload) => {
        state.popup = payload;
    }),
    saveProjectContacts: thunk(async (actions, payload) => {
        actions.setProjectContacts(payload);
    }),
    saveAddresses: thunk(async (actions, payload) => {
        actions.setAddresses(payload);
    }),
    saveParentOrganisation: thunk(async (actions, payload) => {
        try {
            const message = 'Bedrijf ' + payload.name + ' werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/organisation/new', payload);


            if (data.id) {
                toast.success(message)


                if (payload.widget == "project") {
                    actions.addProjectOrganisation(data);
                } else if (payload.widget == "contact") {
                    actions.addContactOrganisation(data);
                } else {
                    actions.addOrganisationContact(data);
                }

            } else {

                toast.error("Probleem bij aanmaken contact.");
            }


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken contact.")

        }
    }),
    saveOrganisation: thunk(async (actions, payload) => {
        try {

            const message = 'Bedrijf ' + payload.name + ' werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/organisation/new', payload);


            if (data.id) {
                toast.success(message)
                actions.addOrganisation(data);
            } else {

                toast.error("Probleem bij aanmaken bedrijf.");
            }


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken bedrijf.")

        }
    }),
    saveArticleFromImport: thunk(async (actions, payload) => {
        try {

            const message = 'Artikel ' + payload.number + ' werd aangemaakt!';
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/article/new-from-import', payload);


            if (data.id) {
                toast.success(message)
                actions.addArticleCompressed({id: data.id, number: payload.number});
            } else {

                toast.error("Probleem bij aanmaken artikel.");
            }


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken artikel.")

        }
    }),
    submitContact: thunk(async (actions, payload) => {
        try {

            if (!payload.id) {
                payload.id = 0;
            }
            const message = 'Contact ' + payload.firstName + ' werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/contact/update/' + payload.id, payload);


            if (data.id) {
                toast.success(message)
                actions.setContact(data);
            } else {

                toast.error("Probleem bij aanmaken contact.");
            }


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken contact.")

        }
    }),
    saveContact: thunk(async (actions, payload) => {
        try {

            const message = 'Contact ' + payload.firstName + ' werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/contact/new', payload);


            if (data.id) {
                toast.success(message)
                actions.addContact(data);
            } else {

                toast.error("Probleem bij aanmaken contact.");
            }


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken contact.")

        }
    }),
    saveNews: thunk(async (actions, payload) => {
        try {

            const message = 'Nieuws ' + payload.name + ' werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/news/new', payload);


            if (data.id) {
                toast.success(message)
                actions.addNews(data);
            } else {

                toast.error("Probleem bij aanmaken nieuws.");
            }


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken nieuws.")

        }
    }),
    saveArticleDetails: thunk(async (actions, payload) => {
        try {

            const message = 'Nieuws ' + payload.name + ' werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/library/article/update', payload);


            if (data.success) {


                if (!payload.id) {
                    payload.id = data.success;
                    actions.addArticleCompressedById(payload);
                    actions.setActiveArticle(null);
                } else {
                    payload.saved = true;
                    actions.setActiveArticle(payload);
                }


            } else {

                toast.error("Probleem bij aanpassen artikel.");
            }


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanpassen artikel.")

        }
    }),
    importData: thunk(async (actions, payload) => {
        try {

            const message = 'Data werd geïmporteerd.';
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/import/data/' + payload.id, payload);


            if (data.id) {
                toast.success(message);
                actions.addImportLink(data);
            } else {
                toast.error("Probleem bij importeren data.");
            }
        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij importeren data.")

        }
    }),
    saveSetting: thunk(async (actions, payload) => {
        try {

            const message = 'Setting ' + payload.name + ' werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/settings/' + payload.type + '/new', payload);


            if (data.id) {
                toast.success(message);
                data.category = (payload.category ? payload.category : "main");

                if (payload.type == "contact") {
                    actions.addContactSetting(data);
                } else if (payload.type == "organisation") {
                    actions.addOrganisationSetting(data);
                } else {
                    actions.addSetting(data);
                }


            } else {
                toast.error("Probleem bij aanmaken setting.");
            }
        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken setting." + err.message)

        }
    }),
    changeProjectArticleWeights: thunk(async (actions, payload) => {
        try {

            const message = 'Project atikels werden omgewisseld.';
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/change-project-article-weights', payload);


            if (data.dragId) {

            } else {
                toast.error("Probleem bij omwisseling.");
            }
        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij omwisseling.")

        }
    }),
    changeArticleWeights: thunk(async (actions, payload) => {
        try {

        const message = 'Artikels werden omgewisseld.';
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/change-article-weights', payload);


            if (data.children) {

            } else {
                toast.error("Probleem bij omwisseling.");
            }
        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij omwisseling.")

        }
    }),
    saveParentAddress: thunk(async (actions, payload) => {
        try {

            const message = 'Adres ' + payload.label + ' werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/address/new', payload);

            if (data.id) {
                // toast.success(message);

                if (payload.parent == "organisation") {
                    actions.addOrganisationAddress(data);
                } else {
                    actions.addAddress(data);
                }


                return data.id;
            } else {
                toast.error("Probleem bij aanmaken adres.");
            }


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken adres.")

        }
    }),
    saveParentMessage: thunk(async (actions, payload) => {
        try {

            const message = 'Bericht ' + payload.subject + ' werd ' + (payload.id ? 'aangepast' : 'aangemaakt!') + ".";
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/message/new', payload);

            if (data.id) {
                // toast.success(message);
                actions.addMessage(data);

                return data.id;
            } else {
                toast.error("Probleem bij aanmaken bericht.");
            }


        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken bericht.")

        }
    }),
    saveArticle: thunk(async (actions, payload) => {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/project/new', payload);
            toast.success(`Project ${payload.label} werd aangemaakt.`)

            actions.addProject(data);

        } catch (err) {
            actions.setError(err.message);
            toast.error("Probleem bij aanmaken project.")

        }
    }),
    loadZipCodes: thunk(async (actions, payload) => {
        fetch(process.env.REACT_APP_API_URL + '/api/load-zip-codes/' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((result) => {

                actions.setZipCodes(result);
            });
    }),
    loadArticles: thunk(async (actions, payload) => {
        /*        kokoFetch(process.env.REACT_APP_API_URL + '/api/article')
                    .then((response) => {
                        const processedArticles = processArticles(response.data);
                        actions.setArticles(processedArticles);
                        actions.setArticlesTree(buildArticleTree(processedArticles));
                    })
                    .catch(() => {
                        alert("Oei, er ging iets mis...")
                    })*/
    }),
    loadContactTypes: thunk(async (actions, payload) => {
        fetch(process.env.REACT_APP_API_URL + '/api/contact/types', {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((contactTypes) => {


                let result = Object.values(contactTypes);
                let options = [];
                if (result) {
                    result.forEach(function (obj) {
                        options.push({'label': obj.typeContact, 'id': obj.typeContact});
                    });
                }


                actions.setContactTypes(options);
            });
    }),
    loadContactSubTypes: thunk(async (actions, payload) => {
        fetch(process.env.REACT_APP_API_URL + '/api/contact/subtypes', {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((contactTypes) => {


                let result = Object.values(contactTypes);
                let options = [];
                if (result) {
                    result.forEach(function (obj) {
                        options.push({'label': obj.subTypeContact, 'id': obj.subTypeContact});
                    });
                }


                actions.setContactSubTypes(options);
            });
    }),
    loadMessage: thunk(async (actions, payload) => {
        actions.setLoading(true);
        //anders toont het vorige item soms nog...
        actions.setMessage(null);
        fetch(process.env.REACT_APP_API_URL + '/api/message/' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                actions.setLoading(false);
                actions.setMessage(result);
            });
    }),
    loadNewsItem: thunk(async (actions, payload) => {
        actions.setLoading(true);
        //anders toont het vorige item soms nog...
        actions.setNewsItem(null);
        fetch(process.env.REACT_APP_API_URL + '/api/news-detail/' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                actions.setLoading(false);
                actions.setNewsItem(result);
            });
    }),
    loadUser: thunk(async (actions, payload) => {

        actions.setLoading(true);
        fetch(process.env.REACT_APP_API_URL + '/api/user/' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                actions.setLoading(false);
                actions.setUser(result);
            });
    }),
    loadProfile: thunk(async (actions, payload) => {
        actions.setLoading(true);
        fetch(process.env.REACT_APP_API_URL + '/api/profile', {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                actions.setLoading(false);
                actions.setUser(result);
            });
    }),
    loadContact: thunk(async (actions, payload) => {
        //anders toont het vorige item soms nog...
        actions.setContact(null);
        actions.setLoading(true);
        fetch(process.env.REACT_APP_API_URL + '/api/contact/' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                actions.setLoading(false);
                actions.setContact(result);
            });
    }),
    loadOrganisation: thunk(async (actions, payload) => {
        //anders toont het vorige item soms nog...
        actions.setOrganisation(null);
        actions.setLoading(true);
        fetch(process.env.REACT_APP_API_URL + '/api/organisation/' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((result) => {

                actions.setLoading(false);

                /* let result = Object.values(settings);*/

                actions.setOrganisation(result);
            });
    }),
    loadSettings: thunk(async (actions, payload) => {

        actions.setLoading(true);
        fetch(process.env.REACT_APP_API_URL + '/api/settings/' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((settings) => {

                actions.setLoading(false);

                let result = Object.values(settings);

                if (result) {
                    result.forEach(function (obj) {
                        obj.label = obj.name;
                    });
                }


                if (payload == "contact") {
                    actions.setContactSettings(result);
                } else if (payload == "organisation") {
                    actions.setOrganisationSettings(result);
                } else {
                    actions.setSettings(result);
                }

            });
    }),
    loadContractorType: thunk(async (actions, payload) => {
        fetch(process.env.REACT_APP_API_URL + '/api/get-contractor-type', {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((data) => {

                actions.setContractorType(data);
            });
    }),
    loadOrganisations: thunk(async (actions, payload) => {
        fetch(process.env.REACT_APP_API_URL + '/api/organisation?type=' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((data) => {

                actions.setOrganisations(Object.values(data));
            });
    }),
    loadArticlesBySearch: thunk(async (actions, payload) => {


        if (payload) {
            try {

                actions.setSearching(true);

                const {data} = await axios.post(process.env.REACT_APP_API_URL + '/api/search-articles', {"search": payload});

                if (data.message) {
                    actions.setSearching(false);

                    actions.setArticlesBySearch(data.ids);
                }
            } catch (err) {
                actions.setError(err.message);
                toast.error("Probleem bij het zoeken.")
            }
        } else {
            actions.setArticlesBySearch([]);
        }
    }),
    loadArticlesCompressed: thunk(async (actions, payload) => {
        fetch(process.env.REACT_APP_API_URL + '/api/article-compressed?mode=' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((data) => {

                actions.setArticlesCompressed(data.list);
                actions.setArticlesParents(data.parents);
            })
    }),
    loadArticle: thunk(async (actions, payload) => {
        fetch(process.env.REACT_APP_API_URL + '/api/article-detail/' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((data) => {

                actions.setActiveArticle(data);
            })
    }),
    loadArticleFacets: thunk(async (actions, payload) => {
        fetch(process.env.REACT_APP_API_URL + '/api/article/section', {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((articleFacets) => {

                actions.setArticleFacets(Object.values(articleFacets));
            });
    }),
    loadContacts: thunk(async (actions, payload) => {
        fetch(process.env.REACT_APP_API_URL + '/api/contact', {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((contactData) => {

                actions.setContacts(Object.values(contactData));
            });
    }),
    loadProjectTemplates: thunk(async (actions, payload) => {

        fetch(process.env.REACT_APP_API_URL + '/api/templates', {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((projectData) => {

                actions.setProjectTemplates(Object.values(projectData));
            });
    }),
    loadMessages: thunk(async (actions, payload) => {

        fetch(process.env.REACT_APP_API_URL + '/api/messages', {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((messageData) => {

                actions.setMessages(Object.values(messageData));
            });
    }),
    loadNews: thunk(async (actions, payload) => {

        fetch(process.env.REACT_APP_API_URL + '/api/news', {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((newsData) => {

                actions.setNews(Object.values(newsData));
            });
    }),
    loadProjects: thunk(async (actions, payload) => {
        actions.setLoading(true);

        fetch(process.env.REACT_APP_API_URL + '/api/project?mode=' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((projectData) => {
                actions.setLoading(false);


                actions.setProjects(Object.values(projectData));
            });
    }),
    loadProjectContractors: thunk(async (actions, payload) => {

        fetch(process.env.REACT_APP_API_URL + '/api/project-contractors/' + payload, {
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            }),
        })
            .then((response) => response.json())
            .then((contractors) => {


                actions.setProjectContractors(contractors);
            });
    }),
    openIdLibrary: '',
    openIdHeights: [],
    setOpenIdLibrary: action((state, payload) => {
        state.openIdLibrary = payload;
    }),
    setOpenIdHeight: action((state, payload) => {
        state.openIdHeights = payload;
    }),

});
