export default {
  modal: {
    base: 'w-full bg-primary-main rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-5xl koko-modal',
  },
  modalBody: {
    base: 'modal-body bg-white px-6 text-sm text-gray-700 dark:text-gray-400',
  },
  // ModalFooter
  modalFooter: {
    base: 'bg-white flex flex-col items-center justify-end px-6 py-3  space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800',
  },
  input: {
    base: 'rounded-full w-full block border-2 text-lg border-peach xl:mb-0 mb-5 px-4 py-3 text-black placeholder-peach',
  },

  select: {
    base: 'rounded-full w-full block border-2 text-lg border-peach px-4 py-3 text-black placeholder-peach',
  },
  button: {
    base: 'bg-primary-main rounded-full font-semibold text-lg',
  },
  backdrop: {
    base: 'modal-wrapper fixed inset-0 z-40 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center',
  },
  // ModalHeader
  modalHeader: {
    base: 'px-6 pt-4 text-white  mb-2 text-lg font-semibold ',
  },
  tableBody: {
    base: 'divide-y dark:divide-gray-700 dark:bg-gray-800 text-gray-700 dark:text-gray-400',
  },
  tableHeader: {
    base: 'border-b border-primary-test text-primary-1000 font-bold text-xl',
  },
  tableRow: {
    base: 'border-b border-primary-test text-primary-test',
  },
  tableContainer: {
    base: '',
  },
};
