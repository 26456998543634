import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import { SearchProvider } from './context/SearchContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
import { StoreProvider } from 'easy-peasy';
import { store } from './store/store';
import calqiTheme from "./calqiTheme";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import Loader from "./components/KokoComponents/Loader";

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

const isProduction = process.env.NODE_ENV === 'production';


ReactDOM.render(
    <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loader/>} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
    <StoreProvider store={store}>
      <SidebarProvider>
          <SearchProvider>
            <Suspense fallback={<ThemedSuspense />}>
              <Windmill theme={calqiTheme} usePreferences>
                  <DndProvider backend={HTML5Backend}>
                        <App />
                  </DndProvider>
              </Windmill>
            </Suspense>
          </SearchProvider>
      </SidebarProvider>
    </StoreProvider>
    </CacheBuster>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
