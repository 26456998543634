import React, {useContext} from 'react'

import CalqiLogoFull from '../../assets/img/calqi_logo_full.svg'
import {NavLink} from "react-router-dom";

function Logo() {
    return (
        <NavLink to={"/app"} className={"relative z-40"}>
            <img src={CalqiLogoFull} className={"w-24"}/>
        </NavLink>
    )
}

export default Logo
