import {kokoFetch} from "./fetchHelpers";

export async function calculateLine(data) {


    //$dimension = $projectArticleHelper->getMeasurementDimension($bodyData['measuringUnit']);
    //$number = $bodyData['number'] ?? 0;
    //$length = $bodyData['length '] ?? 0;
    //$width = $bodyData['width'] ?? 0;
    //$height = $bodyData['height'] ?? 0;

    try {
        const res = await kokoFetch(`${process.env.REACT_APP_API_URL}/api/project-article/calc`, 'POST', data);
        return res;
    } catch (error) {
        // Handle errors
    }


    /*return kokoFetch(`${process.env.REACT_APP_API_URL}/api/client/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
    })*/
}






export function preformatFloat(float){
    float = String(float);

    if(!float){
        return '';
    };

    //Index of first comma
    const posC = float.indexOf(',');

    if(posC === -1){
        //No commas found, treat as float
        return float;
    };

    //Index of first full stop
    const posFS = float.indexOf('.');

    if(posFS === -1){
        //Uses commas and not full stops - swap them (e.g. 1,23 --> 1.23)
        return float.replace(/\,/g, '.');
    };

    //Uses both commas and full stops - ensure correct order and remove 1000s separators
    return ((posC < posFS) ? (float.replace(/\,/g,'')) : (float.replace(/\./g,'').replace(',', '.')));
};
